import React from "react";
import {Droppable} from "react-beautiful-dnd";
import DraggableMonth from "./draggableMonth";

type SeasonType = {
  name: string;
  label: string;
  months: Array<string>;
};

const SeasonDropContainer: React.FC<{
  season: SeasonType;
  droppedMonths: Array<string>;
  monthRefs: any;
}> = ({season, droppedMonths, monthRefs}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        
      }}
    >

      <Droppable droppableId={season.name}
                 direction="vertical">
        {(provided, snapshot) => (
          <div
            className="months-game-season-drop-container"
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/images/monthsGame/${season.label}.png)`,
              backgroundSize: "cover"
            }}

          >
      <span
        style={{
          display: "flex",
          width: "100%",
          padding: "0.5rem",
          justifyContent: "center",
          textTransform: "uppercase",
          fontWeight: "bold",
          textShadow: "-1px 0 white, 0 1px white, 1px 0 white, 0 -1px white",
          fontSize: "4rem"
        }}
      >
        {season.label}
      </span>
            <div
              className="months-game-season-drop-container-dropped-months"

              style={{
                background: snapshot.isDraggingOver ? 'lightBlue' : 'none',
                width: '100%',
                height: '100%',
                opacity: "80%"
              }}
              ref={provided.innerRef}
            >
              {droppedMonths?.map((month, idx) => (
                <DraggableMonth key={idx} month={month} idx={idx} audioRef={monthRefs.get(month)}/>
              ))}
              {provided.placeholder}
            </div>
          </div>
        )}
      </Droppable>
    </div>
  );
};

export default SeasonDropContainer;
