import React, {useEffect} from "react";
import GameHeading from "../common/gameHeading";
import GameContent from "../common/gameContent";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store/store";
import { CompareQuantitiesGameReducerStateType } from "./reducers/compareQuantitiesGameReducer";
import {
  SET_COMPARE_QUANTITIES_GAME_COMPLETED,
  SET_COMPARE_QUANTITIES_GAME_CURRENT_QUESTION,
  SET_COMPARE_QUANTITIES_GAME_SELECTED_ANSWER,
  SET_COMPARE_QUANTITIES_GAME_STARTED
} from "./actions/compareQuantitiesGameActions";
import GameQuestionCounter from "../common/gameQuestionCounter";
import {POST_GAME_RESULTS, SET_GAME_CURRENT_GAME} from "../game/actions/gameActions";
import AudioBtn from "../common/audioBtn";
import AudioText from "../common/audioText";

const CompareQuantitiesGame: React.FC<{
  questions: Array<{
    questionType: string;
    options: Array<{ imgPath: string; amount: number }>;
  }>;
}> = ({ questions }) => {
  const dispatch = useDispatch();
  const currentGame = useSelector<AppState, number>(state => state.game.currentGame);
  const gameState = useSelector<
    AppState,
    CompareQuantitiesGameReducerStateType
  >(state => state.compareQuantitiesGame);
  const gameResults = useSelector<AppState, any>(state => state.game.results);
  const handleGameStart = () => {
    dispatch(
      POST_GAME_RESULTS(
        Object.assign({ ...gameResults }, { compareQuantitiesGame: [] })
      )
    );
    dispatch(SET_COMPARE_QUANTITIES_GAME_SELECTED_ANSWER(0));
    dispatch(SET_COMPARE_QUANTITIES_GAME_CURRENT_QUESTION(0));
    dispatch(SET_COMPARE_QUANTITIES_GAME_STARTED());
  };

  useEffect(() => {
    handleGameStart();
  },[])

  const handleItemClick = (expectedAnswer: number, selectedAnswer: number) => {
    dispatch(SET_COMPARE_QUANTITIES_GAME_SELECTED_ANSWER(selectedAnswer));
    let resultsTempArr = [...gameResults.compareQuantitiesGame];
    let isCorrect = selectedAnswer === expectedAnswer;

    resultsTempArr.push({
      correct: isCorrect,
      expected: expectedAnswer,
      answer: selectedAnswer
    });
    dispatch(
      POST_GAME_RESULTS(
        Object.assign(
          { ...gameResults },
          { compareQuantitiesGame: resultsTempArr }
        )
      )
    );
    getNextQuestion();
  };

  const handleGameCompletion = () => {
    dispatch(SET_COMPARE_QUANTITIES_GAME_COMPLETED());
    dispatch(SET_GAME_CURRENT_GAME(currentGame + 1));
  };

  const renderItem = (option: { imgPath: string; amount: number }) => {
    const questionType = questions[gameState.currentQuestion].questionType;
    let expectedAnswer = 0;

    if (questionType === "more") {
      let arr = [...questions[gameState.currentQuestion].options];
      let max = arr.sort((a, b) => a.amount - b.amount)[arr.length - 1];
      expectedAnswer = max.amount;
    } else if (questionType === "less") {
      let arr = [...questions[gameState.currentQuestion].options];
      let min = arr.sort((a, b) => b.amount - a.amount)[arr.length - 1];
      expectedAnswer = min.amount;
    }

    let items = [];
    for (let i = 0; i < option.amount; i++) {
      items.push({ imgPath: option.imgPath, amount: option.amount, key: option.imgPath + i });
    }

    return (
      <div style={{display: "flex", alignItems: "center", width: "100%"}}>
        <div
          className="compare-quantities-game-item-box"
          style={{
            border:
              gameState.selectedAnswer === option.amount
                ? "2px solid red"
                : "2px solid rgba(0,0,0,.15)"
          }}
          // onClick={() => handleItemClick(expectedAnswer, option.amount)}
          key={option.imgPath}
        >
          {items.map(item => {
            return (
              <div className="compare-quantities-game-item"
                   key={item.key}
              >
                <img
                  style={{ height: "100%", width: "auto" }}
                  alt={item.imgPath}
                  src={process.env.PUBLIC_URL + "/images/compare/" + item.imgPath}
                />
              </div>
            );
          })}
        </div>
        <img onClick={() => handleItemClick(expectedAnswer, option.amount)}
          src={process.env.PUBLIC_URL + "/icons/green-checkmark.svg"}
          style={{height: "100px", marginLeft: "1rem", marginTop: "20px"}}
        />
      </div>
    );
  };

  const renderQuestion = (questionType: string) => {
    if (questionType === "more") {
      return (
        <div className="compare-quantities-game-question">
          <AudioText
              style={{ marginLeft: "1rem" }}
              audioFile="/task8/task8-instruction-more.m4a"
          >
            Leia hulk, milles on <span style={{color: "red"}}>rohkem</span> asju
          </AudioText>
        </div>
      );
    } else if (questionType === "less") {
      return (
        <div className="compare-quantities-game-question">
          <AudioText
              style={{ marginLeft: "1rem" }}
              audioFile="/task8/task8-instruction-less.m4a"
          >
            Leia hulk, milles on <span style={{color: "red"}}>vähem</span> asju
          </AudioText>
        </div>
      );
    } else if (questionType === "shortest") {
      return (
        <div className="compare-quantities-game-question">
          <AudioText
            style={{ marginLeft: "1rem" }}
            audioFile="/task8/task8-instruction-shortest.m4a"
          >
            Leia kõige <span style={{color: "red"}}>lühem</span>
          </AudioText>
        </div>
      )
    } else if (questionType === "longest") {
      return (
        <div className="compare-quantities-game-question">
          <AudioText
            style={{ marginLeft: "1rem" }}
            audioFile="/task8/task8-instruction-longest.m4a"
          >
            Leia kõige <span style={{color: "red"}}>pikem</span>
          </AudioText>
        </div>
      )
    }
  };


  const renderContent = (questionType: string) => {
    if (questionType === "more" || questionType === "less") {
      return (
        <>
        {questions[gameState.currentQuestion].options.map(option => {
            return renderItem(option);
          })}
        </>
      )
    } else if (questionType === "shortest") {
      let item = questions[gameState.currentQuestion].options[0];
      return (
        <div
          style={{width: "50rem"}}
        >
          <svg viewBox="0 0 400 400">

            <image href={process.env.PUBLIC_URL + "/images/compare/" + item.imgPath} x="0" y="0" height="100%" width="100%"/>

            <a onClick={() => handleItemClick(item.amount, 1)}>
              <rect x="26" y="55" width="233" height="44" fill="transparent" />
            </a>
            <a onClick={() => handleItemClick(item.amount, 2)}>
              <rect x="275" y="107" width="113" height="37" fill="transparent" />
            </a>
            <a onClick={() => handleItemClick(item.amount, 3)}>
              <rect x="35" y="127" width="182" height="38" fill="transparent" />
            </a>
            <a onClick={() => handleItemClick(item.amount, 4)}>
              <rect x="97" y="195" width="272" height="44" fill="transparent" />
            </a>
            <a onClick={() => handleItemClick(item.amount, 5)}>
              <rect x="7" y="261" width="221" height="45" fill="transparent" />
            </a>
            <a onClick={() => handleItemClick(item.amount, 6)}>
              <rect x="262" y="307" width="138" height="39" fill="transparent" />
            </a>

          </svg>

        </div>
      )
    } else if (questionType == "longest") {
      let item = questions[gameState.currentQuestion].options[0];
      return (
        <div
          style={{width: "50rem"}}
        >
          <svg viewBox="0 0 400 400">

            <image href={process.env.PUBLIC_URL + "/images/compare/" + item.imgPath} x="0" y="0" height="100%" width="100%"/>

            <a onClick={() => handleItemClick(item.amount, 1)}>
              <rect x="0" y="32" width="238" height="79" fill="transparent" />
            </a>
            <a onClick={() => handleItemClick(item.amount, 2)}>
              <rect x="276" y="65" width="138" height="73" fill="transparent" />
            </a>
            <a onClick={() => handleItemClick(item.amount, 3)}>
              <rect x="3" y="165" width="217" height="85" fill="transparent" />
            </a>
            <a onClick={() => handleItemClick(item.amount, 4)}>
              <rect x="253" y="182" width="125" height="72" fill="transparent" />
            </a>
            <a onClick={() => handleItemClick(item.amount, 5)}>
              <rect x="23" y="266" width="220" height="87" fill="transparent" />
            </a>
            <a onClick={() => handleItemClick(item.amount, 6)}>
              <rect x="272" y="305" width="128" height="67" fill="transparent" />
            </a>

          </svg>

        </div>
      )
    }
  }

  const getNextQuestion = () => {
    if (gameState.currentQuestion !== questions.length - 1) {
      dispatch(SET_COMPARE_QUANTITIES_GAME_SELECTED_ANSWER(0));
      dispatch(
          SET_COMPARE_QUANTITIES_GAME_CURRENT_QUESTION(
              gameState.currentQuestion + 1
          )
      );
    } else {
      handleGameCompletion();
    }
  };

  return (
    <React.Fragment>
      <GameHeading heading="10. Hulkade võrdlemine" />
      <GameContent>
        {gameState.gameStarted ? (
          <React.Fragment>
            <GameQuestionCounter
              totalAmountOfQuestions={questions.length}
              currentQuestion={gameState.currentQuestion + 1}
            />
            <div className="compare-quantities-game-items">
              <div className="compare-quantities-game-question">
                {renderQuestion(
                  questions[gameState.currentQuestion].questionType
                )}
              </div>
              {renderContent(questions[gameState.currentQuestion].questionType)}
            </div>
          </React.Fragment>
        ) : (
          ""
        )}
      </GameContent>
    </React.Fragment>
  );
};

export default CompareQuantitiesGame;
