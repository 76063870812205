import React, {useRef} from "react";
import AudioText, {AudioTextRef} from "../../common/audioText";

const MatchOppositeWordCard: React.FC<{
  word: string;
}> = ({word}) => {

  const audioTextRef = useRef<AudioTextRef>(null);

  return (
    <div
      className="match-opposite-words-drop-zone"
      onClick={() => audioTextRef.current?.clickAudio()}
    >
      <div
        className="match-opposite-words-drop-zone-img"
      >
        <AudioText ref={audioTextRef} noClick={true} audioFile={`/task2/task2-antonym-${word.toLowerCase()}.m4a`} style={{          marginTop: "auto",
          marginBottom: "auto", height: "2.2rem"}} border={false}>
          {word}
        </AudioText>
      </div>
      <img
        src={`${process.env.PUBLIC_URL}/images/oppositesGame/${word.toLowerCase()}.png`}
        style={{
          width: "80%",
          marginLeft: "auto",
          marginRight: "auto"
        }}
        alt={word}
      />
    </div>
  )
};

export default MatchOppositeWordCard;
