import React, {createRef, useEffect, useRef, useState} from "react";
import GameHeading from "../common/gameHeading";
import GameDescription from "../common/gameDescription";
import GameContent from "../common/gameContent";
import {Months, Seasons} from "./months";
import SeasonDropContainer from "./seasonDropContainer";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store/store";
import {
  SET_MONTHS_GAME_COMPLETED,
  SET_MONTHS_GAME_CURRENT_MONTH,
  SET_MONTHS_GAME_NO_MONTHS,
  SET_MONTHS_GAME_STARTED
} from "./actions/monthsGameActions";
import StartGameBtn from "../common/startGameBtn";
import {POST_GAME_RESULTS, SET_GAME_CURRENT_GAME} from "../game/actions/gameActions";
import {DragDropContext, Droppable, DropResult, ResponderProvided} from "react-beautiful-dnd";
import DraggableMonth from "./draggableMonth";
import { Button } from "reactstrap";
import AudioText from "../common/audioText";

interface ObjectLiteral {
  [key: string]: any;
}

const MonthsGame: React.FC<{ questions: Array<string> }> = ({questions}) => {
  const gameState = useSelector<AppState, any>(state => state.monthsGame);
  const currentGame = useSelector<AppState, any>(state => state.game.currentGame);
  const gameResults = useSelector<AppState, any>(state => state.game.results);
  const dispatch = useDispatch();
  const [months, setMonths] = useState<Array<string>>([]);

  let lastDragStart = Date.now();
  const [monthRefs, setMonthRefs] = useState<Map<string, any>>(new Map());

  const [droppedMonths, setDroppedMonths] = useState<ObjectLiteral>({"spring": [], "summer": [], "autumn": [], "winter": []});

  const handleGameStart = () => {
    dispatch(SET_MONTHS_GAME_NO_MONTHS(false));
    dispatch(SET_MONTHS_GAME_CURRENT_MONTH(0));
    dispatch(SET_MONTHS_GAME_STARTED());
  };
  useEffect(() => {
    let monthsArr = [
      ...Months.winter,
      ...Months.spring,
      ...Months.summer,
      ...Months.autumn,
    ];
    monthsArr.push(monthsArr.shift() as string); // push "detsember" to end
    setMonths(monthsArr);

    setMonthRefs((monthRefs) => {
      let m = new Map();
      monthsArr.forEach(x => m.set(x, monthRefs.get(x) || createRef<any>()));
      return m;
    })

    handleGameStart();
  }, []);



  const getNextMonth = () => {
    if (gameState.currentMonth + 1 < months.length) {
      dispatch(SET_MONTHS_GAME_CURRENT_MONTH(gameState.currentMonth + 1));
    } else {
      dispatch(SET_MONTHS_GAME_NO_MONTHS(true));
    }
  };


  const getGameResults = () => {
    let resultsArr: { month: string; expected: string; answer: string; correct: boolean; }[] = [];
    Seasons.forEach(s => {
      s.months.forEach(m => {
        let answerSeason = "";
        Seasons.forEach(ss => {
          if (droppedMonths[ss.name].includes(m)) {
            answerSeason = ss.label;
          }
        });

        resultsArr.push({month: m, expected: s.label, answer: answerSeason, correct: s.label === answerSeason})
      });
    });
    console.log(resultsArr);
    return resultsArr;
  }

  const handleGameCompletion = () => {
    let resultsArr = getGameResults();
    dispatch(
      POST_GAME_RESULTS(
        Object.assign({...gameResults}, {monthsGame: resultsArr})
      )
    );
    dispatch(SET_MONTHS_GAME_COMPLETED());
    dispatch(SET_GAME_CURRENT_GAME(currentGame + 1));
  }

  function playAudio(month: string) {
    const audio = new Audio(process.env.PUBLIC_URL + "/audio/" + `/task4/task4-month-${month}.m4a`);
    audio.play();
  }

  function onDragStart(initial: DropResult, provided: ResponderProvided) {
    lastDragStart = Date.now();
  }

  function onDragEnd(result: DropResult, provided: ResponderProvided) {
    if (Date.now() - lastDragStart < 500 && result.source?.droppableId == result.destination?.droppableId) {
      monthRefs.get(result.draggableId)?.current?.clickAudio();
    }
    if (result.destination == null) {
      return;
    }

    let destinationLabel = result.destination.droppableId;

    if (destinationLabel === "source") {
      return;
    }

    let sourceLabel = result.source.droppableId;

    let tmpDroppedMonths = Object.fromEntries(Object.entries(droppedMonths).map(([k, v]) => [k, [...v]])); // deep copy

    if (sourceLabel !== "source") {
      const sourceArrayIdx = tmpDroppedMonths[sourceLabel].indexOf(result.draggableId);
      if (sourceArrayIdx !== -1) tmpDroppedMonths[sourceLabel].splice(sourceArrayIdx, 1); // remove from source
    } else {
      getNextMonth();
    }

    tmpDroppedMonths[destinationLabel].splice(result.destination.index, 0, result.draggableId); // add to destination

    setDroppedMonths(tmpDroppedMonths);
  }

  return (
    <>
      <GameHeading heading={"6. KUUDE NIMETUSED"}/>
      <GameDescription>
        <AudioText audioFile="/task4/task4-tutorial.m4a" style={{marginLeft: "1rem"}}>
          Vali igale kuu nimetusele sobiv aastaaeg
        </AudioText>

        {/*<AudioBtn*/}
        {/*  style={{marginLeft: "1rem"}}*/}
        {/*  audioFile="/task4/task4-tutorial.m4a"*/}
        {/*/>*/}
      </GameDescription>
      <GameContent>
        {gameState.gameStarted ? (
          <React.Fragment>

            <div>

              <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
                <div
                  className="months-game-seasons-container"
                  style={{
                    display: 'flex',
                  }}
                >
                  {Seasons.map(season =>
                      <SeasonDropContainer key={season.label} season={season} droppedMonths={droppedMonths[season.name]} monthRefs={monthRefs}/>

                  )}

                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '5rem',
                  }}
                >

                {(!gameState.noMonths) ? 
                
                (<Droppable droppableId={"source"} isDropDisabled={true}>
                    {provided =>
                      <>
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          style={{
                            maxWidth: '50rem',
                          }}>
                          <DraggableMonth month={months[gameState.currentMonth]} idx={0} audioRef={monthRefs.get(months[gameState.currentMonth])}/>
                          {provided.placeholder}
                        </div>
                      </>}
                  </Droppable>) : (
                        <Button
                        color="success"
                        size="lg"
                        style={{ fontSize: "2rem", fontWeight: "bold" }}
                        onClick={handleGameCompletion}
                      >
                        EDASI!
                      </Button>
                  )
                }
                </div>
              </DragDropContext>
            </div>
          </React.Fragment>
        ) : (
          <StartGameBtn
            handleGameStart={handleGameStart}
            gameCompleted={gameState.gameCompleted}
          />
        )}
      </GameContent>
    </>
  );
};

export default MonthsGame;
