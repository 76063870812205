import React, { useEffect} from "react";
import NumberSequencingEmptyFieldGame from "./numberSequencingEmptyFieldGame/numberSequencingEmptyFieldGame";
import MultiBackend, {Preview} from "react-dnd-multi-backend";
import { HTML5toTouch } from "../common/dnd";
import { DndProvider } from "react-dnd";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store/store";
import {
  SET_NUMBER_SEQUENCING_GAME_COMPLETED,
  SET_NUMBER_SEQUENCING_GAME_CURRENT_ASSIGNMENT,
  SET_NUMBER_SEQUENCING_GAME_STARTED
} from "./actions/numberSequencingGameActions";
import {SET_GAME_CURRENT_GAME} from "../game/actions/gameActions";
import NumberSequencingOrderRandomGame from "./numberSequencingOrderRandomGame/numberSequencingOrderRandomGame";
import GameHeading from "../common/gameHeading";

const NumberSequencingGame: React.FC<{ questions: Array<any> }> = ({
  questions
}) => {
  const dispatch = useDispatch();
  const currentGame = useSelector<AppState, number>(state => state.game.currentGame);
  useEffect(() => {
    handleGameStart();
  }, [])
  const gameState = useSelector<AppState, any>(
    state => state.numberSequencingGame
  );

  const handleGameStart = () => {
    dispatch(SET_NUMBER_SEQUENCING_GAME_STARTED());
  };

  const handleNextAssignment = () => {
    if (gameState.currentAssignment + 1 < gameAssignments.length) {
      dispatch(
        SET_NUMBER_SEQUENCING_GAME_CURRENT_ASSIGNMENT(
          gameState.currentAssignment + 1
        )
      );
    } else {
      dispatch(SET_NUMBER_SEQUENCING_GAME_COMPLETED());
      dispatch(SET_GAME_CURRENT_GAME(currentGame + 1));
    }
  };

  const gameAssignments = [
    {
      name: "emptyField",
      component: (
        <NumberSequencingEmptyFieldGame
          questions={questions[0]}
          handleNextAssignment={handleNextAssignment}
        />
      )
    },
    {
      name: "ordering",
      component: (
          <NumberSequencingOrderRandomGame questions={questions[1]} handleNextAssignment={handleNextAssignment} />
      )
    }
  ];

  const GeneratePreview = ({ itemType, item, style }: any) => {
    return (
      <div
        className="number-sequencing-game-element"
        style={{
          ...style
        }}
      >

        <div
          style={{

            height: "15rem",
            width: "auto"
          }}
        >
          <img
            src={`${process.env.PUBLIC_URL}/images/numberSequenceGame/keha.png`}
            style={{
              height: "100%",
              width: "auto"
            }}
          />
          <div
            style={{
              position: "absolute",
              bottom: 0,
              top: 0,
              fontSize: "5rem"
            }}
          >
            <svg
              width="100%"
              height="100%"
              viewBox="0 0 293 459"
              preserveAspectRatio="xMinYMid meet"
            >
              <text
                x="145"
                y="254"
                fontSize="175"
                textAnchor="middle"
              >
                {item.value}
              </text>
            </svg>
          </div>
        </div>

      </div>
    )
  };



  useEffect(() => {
    console.log(questions);
  }, [questions]);

  return (
    <DndProvider backend={MultiBackend} options={HTML5toTouch}>
      <GameHeading heading="8. Arvude Järjestamine" />
        {gameState.gameStarted ? (
          <>
          <div>
            {gameAssignments[gameState.currentAssignment].component}
      <Preview generator={GeneratePreview} />
          </div>
          </>
        ) : (
          ""
        )}
    </DndProvider>
  );
};
export default NumberSequencingGame;
