import React, {useEffect, useState} from "react";
import GameQuestionCounter from "../common/gameQuestionCounter";
import NumberSequencingGameDropzone from "./numberSequencingGameDropzone";
import DraggableSequenceNumber from "./draggableSequenceNumber";
import {shuffleArray} from "../common/helpers/arrayHelpers";
import {NextAssignmentBtn, NextQuestionBtn} from "../common/gameButtons";
import {POST_GAME_RESULTS} from "../game/actions/gameActions";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store/store";
import SequenceNumber from "./sequenceNumber";

const NumberSequence: React.FC<{
    questions: Array<{ availableNumbers: number[]; originalSequence: number[] }>;
    handleNextAssignment: Function
}> = ({questions, handleNextAssignment}) => {
    const dispatch = useDispatch();
    const gameResults = useSelector<AppState, any>(state => state.game.results);
    const [currentQuestion, setCurrentQuestion] = useState<number>(0);
    const [assignmentCompleted, setAssignmentCompleted] = useState<boolean>(
        false
    );
    const [numberOptions, setNumberOptions] = useState<Array<number>>([]);
    const [dropSlots, setDropSlots] = useState<Array<any>>([]);
    useEffect(() => {
        let options: Array<number> = [];
        let dropArr: Array<number | null> = [];
        questions[currentQuestion].originalSequence.forEach(number => {
            if (!questions[currentQuestion].availableNumbers.includes(number)) {
                options.push(number);
                dropArr.push(0);
            } else {
                dropArr.push(number);
            }
        });

        const shuffledOptions = shuffleArray(options);
        setNumberOptions(shuffledOptions);
        setDropSlots(dropArr);
    }, [questions, currentQuestion]);

    const handleDrop = (newSlots: any[], newOptions: any[]) => {
        setDropSlots(newSlots);
        setNumberOptions(newOptions);
    };
    
    const handleNextQuestion = () => {

        let resultsArr: Array<any> = [];
        dropSlots.forEach((dropSlot: number, idx: number) => {
            if (dropSlot === questions[currentQuestion].originalSequence[idx]) {
                resultsArr.push({
                    correct: true,
                    expected: questions[currentQuestion].originalSequence[idx],
                    answer: dropSlot
                });
            } else {
                resultsArr.push({
                    correct: false,
                    expected: questions[currentQuestion].originalSequence[idx],
                    answer: dropSlot
                });
            }
        });
        
        let numberSequencingGameResults = [...gameResults.numberSequencingGame];
        numberSequencingGameResults.push(resultsArr);
        dispatch(
            POST_GAME_RESULTS(
                Object.assign(
                    { ...gameResults },
                    {
                        numberSequencingGame: numberSequencingGameResults
                    }
                )
            )
        );
        console.log(currentQuestion);
        console.log(questions);
        if (currentQuestion + 1 < questions.length) {
            setCurrentQuestion(c => c + 1);
        } else {
            handleNextAssignment();
        }
    }

    const getHead = () => {
        return (      <div
          style={{
              position: "relative",
              // minWidth: 0,
              alignSelf: "center",
              // marginLeft: "-2rem",
              zIndex: 2,
              height: "15rem",
              width: "auto",
              display: "flex",
              alignItems: "flex-end"
          }}
        >
            <img
              src={`${process.env.PUBLIC_URL}/images/numberSequenceGame/pea.png`}
              style={{
                  height: "26rem",
                  width: "auto"
              }}
            />
        </div>);
    }


    const getTail = () => {
        return (<div
          style={{
              position: "relative",
              // minWidth: 0,
              alignSelf: "center",
              marginLeft: "-2%",
              zIndex: -1,
              height: "15rem",
              width: "auto",
              display: "flex",
              alignItems: "flex-end"
          }}
        >
            <img
              src={`${process.env.PUBLIC_URL}/images/numberSequenceGame/saba.png`}
              style={{
                  height: "26rem",
                  width: "auto"
              }}
            />
        </div>);
    }

    return (
        <div className="number-sequencing-game-numArray-container">
            <GameQuestionCounter
                totalAmountOfQuestions={questions.length}
                currentQuestion={currentQuestion + 1}
            />
            <div className="number-sequencing-game-numArray">
                {getHead()}
                {dropSlots.map((dropSlot, idx) => {
                    if (
                        !questions[currentQuestion].availableNumbers.includes(dropSlot)
                    ) {
                        return (
                            <NumberSequencingGameDropzone
                                key={Math.random() + dropSlot + idx}
                                expectedDrop={numberOptions[idx]}
                                value={dropSlot}
                                sequenceIdx={idx}
                                dropArray={dropSlots}
                                handleDrop={handleDrop}
                                availableOptions={numberOptions}
                            />
                        );
                    } else {
                        return (
                          <div
                            key={"k" + dropSlot + idx}
                            style={{marginLeft: "-1%"}}
                          >
                            <SequenceNumber value={dropSlot} key={"drop" + idx} disabled={true}/>
                          </div>
                        );
                    }
                })}
                {getTail()}
            </div>
            <div className="number-sequencing-game-numArray number-sequencing-game-numArray-bottom">
                {numberOptions.map(option => {
                    return (
                        <DraggableSequenceNumber
                            key={Math.floor(Math.random() * 100) + option + Math.random()}
                            value={option}
                        />
                    );
                })}
            </div>
            {!assignmentCompleted &&
            numberOptions.length === 0 &&
            currentQuestion + 1 !== questions.length ? (
                <NextQuestionBtn handleClick={handleNextQuestion} />
            ) : (
                ""
            )}
            {currentQuestion + 1 === questions.length &&
            numberOptions.length === 0 ? (
                <NextAssignmentBtn handleClick={handleNextQuestion} />
            ) : (
                ""
            )}
        </div>
    )
};

export default NumberSequence;