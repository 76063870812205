import React, { useEffect, useState, useRef, useMemo } from "react";
import GameHeading from "../common/gameHeading";
import GameDescription from "../common/gameDescription";
import GameContent from "../common/gameContent";
import { Button } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store/store";
import { DogCanvasGameReducerStateType } from "./reducers/dogCanvasGameReducer";
import {
  SET_DOG_CANVAS_GAME_COMPLETED,
  SET_DOG_CANVAS_GAME_CURRENT_QUESTION,
  SET_DOG_CANVAS_GAME_STARTED
} from "./actions/dogCanvasGameActions";
import {COMPLETE_GAME, POST_GAME_RESULTS} from "../game/actions/gameActions";
import AudioBtn from "../common/audioBtn";
import AudioText from "../common/audioText";

type CanvasDimensionsType = {
  height: number;
  width: number;
};

const DogCanvasGame: React.FC<{ questions: string[] }> = ({ questions }) => {
  const canvasRef = useRef(null);
  const [drawingComplete, setDrawingComplete] = useState<boolean>(false);
  const [initialPosition, setInitialPosition] = useState<{ x: number, y: number }>({x: 0, y: 0});
  const dispatch = useDispatch();
  const [position, setPosition] = useState<{ x: number; y: number }>({
    ...initialPosition
  });
  const gameState = useSelector<AppState, DogCanvasGameReducerStateType>(
    state => state.dogCanvasGame
  );
  const [cellWidth, setCellWidth] = useState<number>(0);

  const gameResults = useSelector<AppState, any>(state => state.game.results);

  const [results, setResults] = useState<any[]>([]);
  const [currentInstruction, setCurrentInstruction] = useState<number>(0);
  const [listenedToInstruction, setListenedToInstruction] = useState<boolean>(false);
  const [hasDrawn, setHasDrawn] = useState<boolean>(false);
  const NUMBER_OF_INSTRUCTIONS = 18;

  const NUMBER_OF_ROWS = 14;
  const NUMBER_OF_COLUMNS = 14;

  const drawGrid = (ctx: any, cellWidth: number) => {
    const lastRowPosition = NUMBER_OF_ROWS * cellWidth;
    const lastColumnPosition = NUMBER_OF_COLUMNS * cellWidth;
    
    for (var x = 0.5; x < lastRowPosition; x += cellWidth) {
      ctx.moveTo(x, 0);
      ctx.lineTo(x, lastRowPosition);
    }

    for (var y = 0.5; y < lastColumnPosition; y += cellWidth) {
      ctx.moveTo(0, y);
      ctx.lineTo(lastColumnPosition, y);
    }

    ctx.moveTo(0, 0);
    // context.lineTo(380, 380);

    ctx.strokeStyle = "#ddd";
    ctx.stroke();

    //get DPI
  };

  const draw = (direction: string) => {
    if (listenedToInstruction) {
      let el: any = canvasRef.current;
      let ctx = el.getContext("2d");
      let drawDistance = cellWidth;
      let tempPosition = { ...position };
      const curQuestion = questions[gameState.currentQuestion];

      ctx?.beginPath();
      ctx.strokeStyle = "#4c4c4c";
      ctx?.moveTo(position.x, position.y);
      let tempResults = [...results];
      switch (direction) {
        case "up":
          {

            tempResults.push({expected: curQuestion, answer: direction, correct: curQuestion === direction})
            tempPosition = Object.assign(tempPosition, {
              y: position.y - drawDistance
            });
          }
          break;
        case "left":
          {
            tempResults.push({expected: curQuestion, answer: direction, correct: curQuestion === direction})
            tempPosition = Object.assign(tempPosition, {
              x: position.x - drawDistance
            });
          }
          break;
        case "right":
          {
            tempResults.push({expected: curQuestion, answer: direction, correct: curQuestion === direction})
            tempPosition = Object.assign(tempPosition, {
              x: position.x + drawDistance
            });
          }
          break;
        case "down":
          {
            tempResults.push({expected: curQuestion, answer: direction, correct: curQuestion === direction})
            tempPosition = Object.assign(tempPosition, {
              y: position.y + drawDistance
            });
          }
          break;
        default: {
          tempPosition = position;
        }
      }
      setResults(tempResults);
      setPosition(tempPosition);
      ctx?.lineTo(tempPosition.x, tempPosition.y);
      ctx.lineWidth = 5;
      ctx?.stroke();
      setHasDrawn(true);
      dispatch(
          SET_DOG_CANVAS_GAME_CURRENT_QUESTION(gameState.currentQuestion + 1)
      );
    } else {
      console.log("NO MO' DRAWING");
    }
  };

  const drawStart = (ctx: any, position: {x: number, y: number}, cellWidth: number) => {
    ctx.beginPath();
    let posData = { ...position };
    ctx?.moveTo(posData.x, posData.y);
    ctx.lineTo(posData.x + 1, posData.y + 1);
    ctx.strokeStyle = "#000000";
    ctx.lineWidth = 3;
    ctx?.stroke();
    const mouthYPosition = posData.y + (cellWidth * 1.5)
    ctx?.moveTo(posData.x, mouthYPosition);
    const mouthLength = posData.x + (cellWidth / 2);
    ctx?.lineTo(mouthLength, mouthYPosition);
    ctx?.stroke();
    // ctx?.moveTo(position.x + 200, position.y - 30);
    ctx?.beginPath();
    const eyeXPosition = posData.x + (cellWidth * 2.4)
    const eyeYPosition = posData.y - (cellWidth * 0.4)
    ctx.arc(eyeXPosition, eyeYPosition, 10, 0, 2 * Math.PI);
    ctx?.stroke();
  };
  
  function getCanvasDimensions(el: any, dpi: any) : CanvasDimensionsType {
    //get CSS height
    //the + prefix casts it to an integer
    //the slice method gets rid of "px"
    let style_height = +getComputedStyle(el)
        .getPropertyValue("height")
        .slice(0, -2);
    //get CSS width
    let style_width = +getComputedStyle(el)
        .getPropertyValue("width")
        .slice(0, -2);
    //scale the canvas
    const height = style_height;
    const width = style_width;

    return {height: height, width: width};
  }
  
  //get canvas
  //get context
  function fix_dpi(el: any, canvasDimensions: CanvasDimensionsType) {
    el.setAttribute("height", canvasDimensions.height);
    el.setAttribute("width", canvasDimensions.width);
  }

  function getCellWidth(canvasDimensions: CanvasDimensionsType) : number {
    return canvasDimensions.width / NUMBER_OF_COLUMNS;
  }

  const initializeDrawing = () => {
    dispatch(SET_DOG_CANVAS_GAME_CURRENT_QUESTION(0));

    const el: any = canvasRef.current;
    el.width = 420;
    el.height = 420;
    let dpi = window.devicePixelRatio;

    const canvasDimesions = getCanvasDimensions(el, dpi);
    fix_dpi(el, canvasDimesions);

    const cellWidth = getCellWidth(canvasDimesions);
    setCellWidth(cellWidth);
    
    const initialPosition = {x: 3 * cellWidth, y: 5 * cellWidth};
    
    setDrawingComplete(false);
    setPosition(initialPosition);
    setInitialPosition(initialPosition);
    
    const ctx = el.getContext("2d");
    ctx.clearRect(0, 0, ctx.width, ctx.height);
    console.log(position);
    
    drawGrid(ctx, cellWidth);
    drawStart(ctx, initialPosition, cellWidth);
  };

  const handleGameStart = () => {
    dispatch(SET_DOG_CANVAS_GAME_STARTED());
  };

  useEffect(() => {
    handleGameStart();
  }, []);

  useEffect(() => {
    if (gameState.gameStarted) {
      setDrawingComplete(false);
      initializeDrawing();
    }
  }, [gameState.gameStarted]);

  const handleGameCompletion = () => {
    let tempResults = [...results]
    while (tempResults.length < questions.length) {
      tempResults.push({expected: questions[tempResults.length], correct: false})
    }
    dispatch(POST_GAME_RESULTS(Object.assign({...gameResults}, {dogCanvasGame: tempResults})))
    dispatch(SET_DOG_CANVAS_GAME_COMPLETED());
    dispatch(COMPLETE_GAME());
  }
  
  const canDraw = () => {
    return listenedToInstruction;
  }
  
  const handleNextInstruction = () => {
    setCurrentInstruction(c => c + 1);
    setListenedToInstruction(true);
    setHasDrawn(false);
  }

  return (
    <React.Fragment>
      <GameHeading heading="12. Joonistamine" />
      <GameDescription>
        <AudioText
            style={{ marginLeft: "1rem" }}
            audioFile="/task10/task10-tutorial.m4a"
        >
          Kuula tähelepanelikult käsklusi ning liigu nooltega vastavalt juhistele
        </AudioText>
      </GameDescription>
      <GameContent>
        {gameState.gameStarted ? (
            <React.Fragment>
              <div
                  className="dog-canvas-game-instructions-wrapper"
              >
              </div>
              <div className="dog-canvas-game-box-wrapper">
                <canvas
                    className="gridCanvas"
                    ref={canvasRef}
                    style={{backgroundColor: "white"}}
                />
                <div className="dog-canvas-game-buttons">
              <span
                  className="dog-canvas-game-instructions"
                  style={{marginBottom: "3rem"}}
              >
                {
                      <span
                        className={currentInstruction < NUMBER_OF_INSTRUCTIONS && (hasDrawn || currentInstruction === 0) ? "" : "hidden"}
                      >
                        <AudioText
                            style={{marginLeft: "1rem"}}
                            divStyle={{backgroundColor: "#a4ffa7", borderColor: "green"}}
                            audioFile={`/task10/task10-instruction${currentInstruction}.m4a`}
                            onClick={handleNextInstruction}
                            unpausable={true}
                            disabled={currentInstruction > NUMBER_OF_INSTRUCTIONS}
                        >
                          KUULA
                        </AudioText>
                      </span>
                }
              </span>
                  <div
                      className="dog-canvas-game-button"
                      onClick={() => draw("up")}
                  >
                    <img
                        src={process.env.PUBLIC_URL + "/icons/arrow.svg"}
                        style={{transform: "rotate(180deg)"}}
                        className={canDraw() ? "dog-canvas-game-button-icon" : "dog-canvas-game-button-icon disabled"}
                    />
                  </div>
                  <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%"
                      }}
                  >
                    <div
                        className="dog-canvas-game-button"
                        onClick={() => draw("left")}
                    >
                      <img
                          src={process.env.PUBLIC_URL + "/icons/arrow.svg"}
                          style={{transform: "rotate(90deg)"}}
                          className={canDraw() ? "dog-canvas-game-button-icon" : "dog-canvas-game-button-icon disabled"}
                      />
                    </div>
                    <div
                        className="dog-canvas-game-button"
                        onClick={() => draw("right")}
                    >
                      <img
                          src={process.env.PUBLIC_URL + "/icons/arrow.svg"}
                          style={{transform: "rotate(270deg)"}}
                          className={canDraw() ? "dog-canvas-game-button-icon" : "dog-canvas-game-button-icon disabled"}
                      />
                    </div>
                  </div>
                  <div
                      className="dog-canvas-game-button"
                      onClick={() => draw("down")}
                  >
                    <img
                        src={process.env.PUBLIC_URL + "/icons/arrow.svg"}
                        className={canDraw() ? "dog-canvas-game-button-icon" : "dog-canvas-game-button-icon disabled"}
                    />
                  </div>
                  <Button
                      className={hasDrawn && currentInstruction >= NUMBER_OF_INSTRUCTIONS ? "" : "hidden"}
                      color="success"
                      size="lg"
                      style={{
                        fontSize: "2rem",
                        marginTop: "8rem",
                        fontWeight: "bold"
                      }}
                      onClick={handleGameCompletion}
                  >
                    LÕPETA MÄNG
                  </Button>
                </div>
              </div>
            </React.Fragment>
        ) : (
            ""
        )}
      </GameContent>
    </React.Fragment>
  );
};

export default DogCanvasGame;
