import React from "react";
import {MatchOppositeWordsGameQuestionType} from "./matchOppositeWordsGameTypes";
import {Droppable} from "react-beautiful-dnd";
import MatchOppositeWordCard from "./matchOppositeWordCard";
import MatchOppositeWordDraggableWord from "./matchOppositeWordDraggableWord";

const MatchOppositeWordDropZone: React.FC<{
  question: MatchOppositeWordsGameQuestionType;
  item: string;
  idx: number;
  wordRefs: any;
}> = ({question, item, idx, wordRefs}) => {

  return (
    <div>
      <MatchOppositeWordCard word={question.word} />
      <Droppable droppableId={idx.toString()}
                 direction="vertical">
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            <div
              className="match-opposite-words-drop-zone-box"
              style={{fontSize: "3rem", background: snapshot.isDraggingOver ? 'lightBlue' : 'gray',}}
            >
              {item !== "" &&
              <MatchOppositeWordDraggableWord word={{word: item}} idx={0} audioRef={wordRefs.get(item)}/>
              }
            </div>
            {/*{provided.placeholder}*/}
          </div>
        )}
      </Droppable>
    </div>
  )
};

export default MatchOppositeWordDropZone;
