import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { POST_AUDIO_TO_TEXT_SELECTED_ANSWER } from "./actions/audioToTextGameActions";
import AudioBtn from "../common/audioBtn";
import AudioText from "../common/audioText";

type AudioToTextOptionType = {
  label: string;
  correct: boolean;
};

const AudioToTextOption: React.FC<{
  option: AudioToTextOptionType;
  optionNo: number;
  question: string;
  questionNo: number;
  handleQuestionAnswer: any;
  selectedAnswer: string;
}> = ({ option, optionNo, question, questionNo, handleQuestionAnswer, selectedAnswer }) => {
  const [checked, setChecked] = useState(false);
  const dispatch = useDispatch();

  const handleOptionCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.value);
    dispatch(POST_AUDIO_TO_TEXT_SELECTED_ANSWER(e.target.value));
  };

  return (
    <div className="audio-to-text-option" style={{display: "flex", flexDirection: "column", marginTop: "6rem"}}>
      <AudioText audioFile={`/task1/task1-option-${questionNo + 1}_${optionNo + 1}.m4a`}>
        {option.label}
      </AudioText>
      <label style={{marginTop: "5rem"}}>
        <input
          type="radio"
          onChange={e => {
            handleOptionCheck(e);
            setChecked(!checked);
            handleQuestionAnswer(e.target.value, question);
          }}
          id={option.label}
          value={option.label}
          checked={selectedAnswer === option.label}
          name={question}
          style={{opacity: 0, width: 0, height: 0}}
        />
        <img
          src={process.env.PUBLIC_URL + "/icons/green-checkmark.svg"}
          style={{height: "8rem"}}
        />
      </label>
    </div>
  );
};

export default AudioToTextOption;
