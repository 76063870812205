import React from "react";
import { useDrop } from "react-dnd";
import { DraggableWordType } from "../common/types/dndTypes";
import DraggableSequenceNumber from "./draggableSequenceNumber";

const NumberSequencingGameDropzone: React.FC<{
  expectedDrop: number;
  value: number;
  handleDrop: (newArr: any[], newOptions: any[]) => void;
  sequenceIdx: number;
  dropArray: any[];
  availableOptions: any[];
}> = ({
  expectedDrop,
  value,
  handleDrop,
  sequenceIdx,
  dropArray,
  availableOptions
}) => {
  const [dropProps, drop] = useDrop({
    accept: DraggableWordType.NUMBER,
    drop: (item: any) => {
      let dropArr = [...dropArray];
      let optionArr = [...availableOptions];

      if (dropArr[sequenceIdx]) { // if spot already has something
        let dragIdx = dropArr.indexOf(item.value); // index of source
        if (dragIdx === -1) { // if item in bottom row
          optionArr[optionArr.indexOf(item.value)] = dropArr[sequenceIdx];
          dropArr[sequenceIdx] = item.value;
        } else {
          dropArr[dragIdx] = dropArr.splice(sequenceIdx, 1, dropArr[dragIdx])[0]; // swap elements
        }
        handleDrop(dropArr, optionArr);
      } else { // slot is empty
        dropArr[sequenceIdx] = item.value; // put value to slot
        if (optionArr.includes(item.value)) {
          optionArr = optionArr.filter(x => x !== item.value);
        } else {
          console.log(item, "DROP IDX");
          // dropArr[sequenceIdx] = item.value;
          dropArr[item.dropIdx] = 0; // empty source slot
          console.log(dropArr);
        }
        handleDrop(dropArr, optionArr);
      }
    },
    canDrop: (item: any) => {
      //TODO
      return true;
    },
    collect: monitor => ({
      isOver: !!monitor.isOver()
    })
  });

  return (
    <div
      ref={drop}

      style={{
        marginLeft: "-1%"
      }}
    >
      {value ? (
        <DraggableSequenceNumber droppedIdx={sequenceIdx} value={value} />
      ) : (
        <DraggableSequenceNumber value={NaN}/>
      )}
    </div>
  );
};

export default NumberSequencingGameDropzone;
