    import React, {useEffect, useRef, useState} from "react";
import GameHeading from "../common/gameHeading";
import GameContent from "../common/gameContent";
import GameDescription from "../common/gameDescription";
import AudioToTextGameQuestion from "./audioToTextGameQuestion";
import {useDispatch, useSelector} from "react-redux";
import {
    SET_AUDIO_TO_TEXT_GAME_COMPLETED, SET_AUDIO_TO_TEXT_GAME_CURRENT_QUESTION,
    SET_AUDIO_TO_TEXT_GAME_STARTED,
    SET_AUDIO_TO_TEXT_GAME_STATE
} from "./actions/audioToTextGameActions";
import {AppState} from "../../store/store";
import {Button} from "reactstrap";
import StartGameBtn from "../common/startGameBtn";
import {POST_GAME_RESULTS, SET_GAME_CURRENT_GAME} from "../game/actions/gameActions";
import AudioBtn from "../common/audioBtn";
import {AudioToTextGameReducerStateType} from "./reducers/audioToTextGameReducer";
    import AudioText from "../common/audioText";

export type AudioToTextGameProps = Array<{
    question: string;
    options: Array<{
        label: string;
        correct: boolean;
        answer?: string;
    }>;
}>;

const AudioToTextGame: React.FC<{ questions?: AudioToTextGameProps }> = ({
                                                                             questions
                                                                         }) => {
    const gameState = useSelector<AppState, AudioToTextGameReducerStateType>(state => state.audioToTextGame);
    const currentGame = useSelector<AppState, number>(state => state.game.currentGame);
    const [showAudioInsteadOfQuestions, setShowAudioInsteadOfQuestions] = useState<boolean>(true);
    const [isAudioEnded, setIsAudioEnded] = useState<boolean>(false);

    const audioPlayer = useRef<HTMLAudioElement>(null);

    const allQuestions = useSelector<AppState, Array<any>>(
        state => state.audioToTextGame.questions
    );

    const currentQuestion = useSelector<AppState, number>(
        state => state.audioToTextGame.currentQuestion
    );


    const gameResults = useSelector<AppState>(state => state.game.results);
    const dispatch = useDispatch();
    useEffect(() => {
        if (!gameState.questions.length) {
            dispatch(SET_AUDIO_TO_TEXT_GAME_STATE(questions));
        }
    }, [dispatch, questions]);

    const handleSetGameStart = () => {
        dispatch(SET_AUDIO_TO_TEXT_GAME_STARTED());
    };

    useEffect(() => {
        handleSetGameStart();
    }, []);

    const handleGameEnd = (answersData: any) => {
        let results: any = gameResults;
        results.audioToTextGame = [];
        answersData.forEach((question: { question: any; answer: any; options: any[]; }) => {
            let result: any = {};
            result.question = question.question;
            result.answer = question.answer;
            let correctOption = question.options.filter((x: any) => x.correct);
            result.expected = correctOption[0].label;
            result.correct = result.answer === result.expected;
            results.audioToTextGame.push(result);
        });
        dispatch(POST_GAME_RESULTS(results));
        dispatch(SET_AUDIO_TO_TEXT_GAME_COMPLETED());
        dispatch(SET_GAME_CURRENT_GAME(currentGame + 1))
    };

    const handleQuestionAnswer = (answer: any, question: any) => {
        let newArr = gameState.questions.map(questionEl => {
            if (questionEl.question === question) {
                return {...questionEl, answer: answer};
            } else {
                return questionEl;
            }
        });

        dispatch(SET_AUDIO_TO_TEXT_GAME_STATE(newArr));
        if (gameState.questions.length > currentQuestion + 1) {
            handleNextQuestion();
        } else {
            handleGameEnd(newArr);
        }
    };

    const handleNextQuestion = () => {
        dispatch(
            SET_AUDIO_TO_TEXT_GAME_CURRENT_QUESTION(currentQuestion + 1)
        );
    };

    const handleStartQuestions = () => {
        setShowAudioInsteadOfQuestions(false);
    }

    const renderAudio = () => {
        return (
            <React.Fragment>
                <div
                    style={{
                        marginBottom: "8rem",
                        display: "flex",
                        alignItems: "center",
                        paddingTop: "10rem"
                    }}
                >
              <span
                  onClick={() => {if (audioPlayer.current == null) return; audioPlayer.current.paused ? audioPlayer.current.play() : audioPlayer.current.pause(); }}
                  style={{
                      fontSize: "3rem",
                      marginRight: "1rem",
                      fontWeight: "bold"
                  }}
              >
                JUTT KUULAMISEKS
              </span>
                    <audio ref={audioPlayer} controls style={{height: "5rem"}} onEnded={x => setIsAudioEnded(true)}>
                        <source
                            src={process.env.PUBLIC_URL + "/audio/task1/task1-story.mp3"}
                            type="audio/mp3"
                        />
                    </audio>
                </div>
                <div className="audio-to-text-complete-game">
                <Button size="lg" onClick={handleStartQuestions} color={isAudioEnded ? "success" : "secondary"}
                        style={{fontWeight: "bold", fontSize: "2rem", float: "right", marginRight: "4rem"}} className="game-next-game-btn">
                    EDASI!
                </Button>
                </div>
            </React.Fragment>
        )
    }

    const renderQuestions = () => {
        return (
            <div className="audio-to-text-questions">
                {currentQuestion < allQuestions.length ? <AudioToTextGameQuestion
                    key={currentQuestion}
                    questionNo={currentQuestion}
                    questionProp={allQuestions[currentQuestion]}
                    selectedAnswer={allQuestions[currentQuestion].answer}
                    handleQuestionAnswer={handleQuestionAnswer}
                /> : null}
            </div>
        );
    }

    return (
        <React.Fragment>
            <GameHeading heading={"1. Teksti mõistmine"}/>
            <GameDescription>
                <AudioText
                    style={{marginLeft: "1rem"}}
                    audioFile="/task1/task1-tutorial.m4a"
                >
                  Kuula tähelepanelikult juttu ja proovi meelde jätta mida jutus kuuled
                </AudioText>
            </GameDescription>
            <GameContent>
                {gameState.gameStarted ?
                    showAudioInsteadOfQuestions ? renderAudio() : renderQuestions()
                    : (
                        <StartGameBtn
                            handleGameStart={handleSetGameStart}
                            gameCompleted={gameState.gameCompleted}
                        />
                    )}
            </GameContent>
        </React.Fragment>
    );
};

export default AudioToTextGame;
