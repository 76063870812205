import React from "react";
import {ImageSequenceGameQuestionType} from "./imageSequenceGame";
import {Draggable} from "react-beautiful-dnd";

const ImageSequenceGameDraggableImage: React.FC<{
  question: ImageSequenceGameQuestionType;
  sequenceIdx: number;
}> = ({question, sequenceIdx}) => {

  return (
    <Draggable key={"" + question.order} draggableId={"" + question.order} index={sequenceIdx}>
      {provided => (
        <div className="image-sequence-game-image"
             ref={provided.innerRef}
             {...provided.draggableProps}
             {...provided.dragHandleProps}
             style={{margin: 25, ...provided.draggableProps.style}}
        >
          <img
            style={{opacity: 1}}
            className="image-sequence-game-option"
            src={process.env.PUBLIC_URL + question.image}
            alt={""}
          />
        </div>
      )}
    </Draggable>
  );
};

export default ImageSequenceGameDraggableImage;
