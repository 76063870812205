import React from "react";
import {Draggable} from "react-beautiful-dnd";
import AudioText from "../../common/audioText";

const MatchOppositeWordDraggableWord: React.FC<{ word: any; idx: number; className?: string; audioRef: any }> = ({
    word,
    idx,
    className,
    audioRef
  }) => {

  return (
          <Draggable key={word.word} draggableId={word.word} index={idx}>
            {(provided) => (
              <div
                className={className}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                onClick={() => audioRef?.current?.clickAudio()}
              >
                <div
                  className="match-opposite-words-drop-zone"
                >
                  <div
                    className="match-opposite-words-drop-zone-img"
                  >
                    <AudioText ref={audioRef} noClick={true} audioFile={`/task2/task2-antonym-${word.word.toLowerCase()}.m4a`} style={{          marginTop: "auto",
                      marginBottom: "auto", height: "2.2rem"}} border={false}>
                      {word.word}
                    </AudioText>
                  </div>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/oppositesGame/${word.word.toLowerCase()}.png`}
                    style={{
                      width: "80%",
                      marginLeft: "auto",
                      marginRight: "auto"
                    }}
                    alt={word.word}
                  />
                </div>
              </div>
            )}
          </Draggable>
  );
};

export default MatchOppositeWordDraggableWord;
