export const gameConfig = {
  audioToTextGame: [
    {
      question: "Mis aastaajal käisid lapsed matkamas?",
      options: [
        { label: "Kevadel", correct: true },
        { label: "Sügisel", correct: false }
      ]
    },
    {
      question: "Milline oli ilm?",
      options: [
        { label: "Vihmane", correct: false },
        { label: "Päikseline", correct: true }
      ]
    },
    // {
    //   question: "Mis oli lasteaia nimi?",
    //   options: [
    //     { label: "Vikerkaare", correct: true },
    //     { label: "Päikese", correct: false }
    //   ]
    // },
    // {
    //   question: "Mis värvi oli buss?",
    //   options: [
    //     { label: "Punane", correct: false },
    //     { label: "Kollane", correct: true }
    //   ]
    // },
    {
      question: "Mis oli bussijuhi nimi?",
      options: [
        { label: "Peeter", correct: false },
        { label: "Mati", correct: true }
      ]
    },
    {
      question: "Mis laulu laulsid lapsed bussis?",
      options: [
        { label: "Rongisõitu", correct: false },
        { label: "Juba linnukesed", correct: true }
      ]
    },
    {
      question: "Kuhu lapsed matkama läksid?",
      options: [
        { label: "Rabasse", correct: true },
        { label: "Mereranda", correct: false }
      ]
    },
    {
      question: "Mis värvi olid Mari kummikud?",
      options: [
        { label: "Rohelised", correct: false },
        { label: "Kollased", correct: true }
      ]
    },
    {
      question: "Mitu korrust oli linnuvaatlustornil?",
      options: [
        { label: "2", correct: false },
        { label: "3", correct: true }
      ]
    },
    // {
    //   question: "Mida lapsed piknikul sõid?",
    //   options: [
    //     { label: "Pirukaid", correct: false },
    //     { label: "Vorsti", correct: true }
    //   ]
    // }
  ],
  dragWordToPictureGame: [
    [
      {
        answer: "Auto",
        options: [
          {
            word: "Auto",
            image: "/images/matchWordWithPictureGame/car.png"
          },
          {
            word: "Ahv",
            image: "/images/matchWordWithPictureGame/monkey.png"
          },
          {
            word: "Arvuti",
            image: "/images/matchWordWithPictureGame/computer.png"
          }
        ]
      },
      {
        answer: "Orav",
        options: [
          {
            word: "Pall",
            image: "/images/matchWordWithPictureGame/ball.png"
          },
          {
            word: "Oks",
            image: "/images/matchWordWithPictureGame/branch.png"
          },
          {
            word: "Orav",
            image: "/images/matchWordWithPictureGame/squirrel.png"
          }
        ]
      },
      {
        answer: "Karu",
        options: [
          {
            word: "Kohver",
            image: "/images/matchWordWithPictureGame/suitcase.png"
          },
          {
            word: "Käru",
            image: "/images/matchWordWithPictureGame/wheelbarrow.png"
          },
          {
            word: "Karu",
            image: "/images/matchWordWithPictureGame/bear.png"
          }
        ]
      },
      {
        answer: "Lill",
        options: [
          {
            word: "Lamp",
            image: "/images/matchWordWithPictureGame/lamp.png"
          },
          {
            word: "Lill",
            image: "/images/matchWordWithPictureGame/flower.png"
          },
          {
            word: "Liblikas",
            image: "/images/matchWordWithPictureGame/butterfly.png"
          }
        ]
      },
      {
        answer: "Nukk",
        options: [
          {
            word: "Nukk",
            image: "/images/matchWordWithPictureGame/doll.png"
          },
          {
            word: "Lukk",
            image: "/images/matchWordWithPictureGame/lock.png"
          },
          {
            word: "Luik",
            image: "/images/matchWordWithPictureGame/swan.png"
          }
        ]
      },
      {
        answer: "Aken",
        options: [
          {
            word: "Aed",
            image: "/images/matchWordWithPictureGame/fence.png"
          },
          {
            word: "Maja",
            image: "/images/matchWordWithPictureGame/house.png"
          },
          {
            word: "Aken",
            image: "/images/matchWordWithPictureGame/window.png"
          }
        ]
      },
      {
        answer: "Hobune",
        options: [
          {
            word: "Sebra",
            image: "/images/matchWordWithPictureGame/zebra.png"
          },
          {
            word: "Hernes",
            image: "/images/matchWordWithPictureGame/peas.png"
          },
          {
            word: "Hobune",
            image: "/images/matchWordWithPictureGame/horse.png"
          }
        ]
      },
      {
        answer: "Rebane",
        options: [
          {
            word: "Rebane",
            image: "/images/matchWordWithPictureGame/fox.png"
          },
          {
            word: "Raadio",
            image: "/images/matchWordWithPictureGame/radio.png"
          },
          {
            word: "Rüütel",
            image: "/images/matchWordWithPictureGame/knight.png"
          }
        ]
      },
      {
        answer: "Kõrvits",
        options: [
          {
            word: "Kõrv",
            image: "/images/matchWordWithPictureGame/ear.png"
          },
          {
            word: "Kõrvits",
            image: "/images/matchWordWithPictureGame/pumpkin.png"
          },
          {
            word: "Kõrrejook",
            image: "/images/matchWordWithPictureGame/juice.png"
          }
        ]
      },
      {
        answer: "Kapsas",
        options: [
          {
            word: "Kamm",
            image: "/images/matchWordWithPictureGame/comb.png"
          },
          {
            word: "Kapsas",
            image: "/images/matchWordWithPictureGame/cabbage.png"
          },
          {
            word: "Vaateratas",
            image: "/images/matchWordWithPictureGame/wheel.png"
          }
        ]
      }
    ],
    [
      [
        { word: "Kurb", image: "KURB", match: "Rõõmus" },
        { word: "Väike", image: "VÄIKE", match: "Suur" },
        { word: "Noor", image: "NOOR", match: "Vana" },
        { word: "Kerge", image: "KERGE", match: "Raske" }
      ],
      [
        { word: "Kiire", image: "KIIRE", match: "Aeglane" },
        { word: "Poiss", image: "POISS", match: "Tüdruk" },
        { word: "Külm", image: "KÜLM", match: "Soe" },
        { word: "Öö", image: "NIGHT", match: "Päev" }
      ]
    ],
    [
      { word: "Pall", options: ["Pall", "Sall"], image: "ball.png"},
      { word: "Kuul", options: ["Kuul", "Kull"], image: "bowling.png" },
      { word: "Loss", options: ["Loss", "Loos"], image: "castle.png" },
      { word: "Koll", options: ["Kool", "Koll"], image: "monster.png" },
      { word: "Nukk", options: ["Sukk", "Nukk"], image: "doll.png" },
      { word: "Nari", options: ["Kali", "Nari"], image: "bed.png" }
    ]
  ],
  imageSequence: [
    [
      { order: 3, image: "/images/imageSequenceGame/1-4.png" },
      { order: 0, image: "/images/imageSequenceGame/1-1.png" },
      { order: 2, image: "/images/imageSequenceGame/1-3.png" },
      { order: 1, image: "/images/imageSequenceGame/1-2.png" },
    ],
    [
      { order: 1, image: "/images/imageSequenceGame/2-2.png" },
      { order: 0, image: "/images/imageSequenceGame/2-1.png" },
      { order: 3, image: "/images/imageSequenceGame/2-4.png" },
      { order: 2, image: "/images/imageSequenceGame/2-3.png" },
    ]
  ],
  monthsGame: [
    "jaanuar",
    "veebruar",
    "märts",
    "aprill",
    "mai",
    "juuni",
    "juuli",
    "august",
    "september",
    "oktoober",
    "november",
    "detsember"
  ],
  weekdayGame: [
    { label: "esmaspäev", audioPath: "", order: 0 },
    { label: "teisipäev", audioPath: "", order: 1 },
    { label: "kolmapäev", audioPath: "", order: 2 },
    { label: "neljapäev", audioPath: "", order: 3 },
    { label: "reede", audioPath: "", order: 4 },
    { label: "laupäev", audioPath: "", order: 5 },
    { label: "pühapäev", audioPath: "", order: 6 }
  ],
  numberSequencingGame: [
    [
      {
        originalSequence: [2, 3, 4, 5, 6, 7, 8],
        availableNumbers: [3, 6]
      },
      {
        originalSequence: [9, 8, 7, 6, 5, 4, 3],
        availableNumbers: [7, 4]
      },
      // {
      //   originalSequence: [4, 5, 6, 7, 8, 9, 10],
      //   availableNumbers: [5, 7]
      // },
      // {
      //   originalSequence: [7, 6, 5, 4, 3, 2, 1],
      //   availableNumbers: [4, 2]
      // }
    ],
    [
      {
        originalSequence: [1, 3, 4, 7, 8, 10],
        availableNumbers: [1]
      },
      {
        originalSequence: [7, 6, 5, 4, 2, 1],
        availableNumbers: [7]
      },
      // {
      //   originalSequence: [4, 5, 6, 7, 8, 9, 10],
      //   availableNumbers: [4]
      // },
      // {
      //   originalSequence: [9, 8, 7, 5, 4, 2],
      //   availableNumbers: [9]
      // }
    ]
  ],
  calculationGame: [
    {
      task: "2 + 8",
      answer: 2 + 8
    },
    {
      task: "10 - 3",
      answer: 10 - 3
    },
    // {
    //   task: "4 + 7",
    //   answer: 4 + 7
    // },
    {
      task: "6 + 6",
      answer: 6 + 6
    },
    {
      task: "8 - 4",
      answer: 8 - 4
    },
    // {
    //   task: "6 - 2",
    //   answer: 6 - 2
    // },
    {
      task: "5 + 4",
      answer: 5 + 4
    },
    {
      task: "9 - 7",
      answer: 9 - 7
    },
    {
      task: "3 + 5",
      answer: 3 + 5
    },
    {
      task: "5 - 3",
      answer: 5 - 3
    }
  ],
  compareQuantitiesGame: [
    {
      questionType: "more",
      options: [
        { imgPath: "burger.png", amount: 6 },
        { imgPath: "chair.png", amount: 8 }
      ]
    },
    {
      questionType: "less",
      options: [
        { imgPath: "hat.png", amount: 10 },
        { imgPath: "chair.png", amount: 9 }
      ]
    },
    {
      questionType: "more",
      options: [
        { imgPath: "shoes.png", amount: 7 },
        { imgPath: "socks.png", amount: 5 }
      ]
    },
    {
      questionType: "less",
      options: [
        { imgPath: "shoes.png", amount: 7 },
        { imgPath: "boxing.png", amount: 8 }
      ]
    },
    {
      questionType: "shortest",
      options: [
        { imgPath: "scarves.png", amount: 2 },
      ]
    },
    {
      questionType: "longest",
      options: [
        { imgPath: "cars.png", amount: 1 },
      ]
    }
  ],
  analogueClockGame: [
    {
      question: "KELL ON 4",
      options: [
        { imgPath: "clock11.png", correct: false },
        { imgPath: "clock12.png", correct: true },
        { imgPath: "clock13.png", correct: false }
      ]
    },
    {
      question: "KELL ON POOL 7",
      options: [
        { imgPath: "clock61.png", correct: false },
        { imgPath: "clock62.png", correct: false },
        { imgPath: "clock63.png", correct: true }
      ]
    },
    {
      question: "KELL ON 6",
      options: [
        { imgPath: "clock51.png", correct: true },
        { imgPath: "clock52.png", correct: false },
        { imgPath: "clock53.png", correct: false }
      ]
    },
    {
      question: "KELL ON POOL 9",
      options: [
        { imgPath: "clock31.png", correct: true },
        { imgPath: "clock32.png", correct: false },
        { imgPath: "clock33.png", correct: false }
      ]
    },
    {
      question: "KELL ON 11",
      options: [
        { imgPath: "clock41.png", correct: false },
        { imgPath: "clock42.png", correct: false },
        { imgPath: "clock43.png", correct: true }
      ]
    },
    {
      question: "KELL ON POOL 12",
      options: [
        { imgPath: "clock21.png", correct: false },
        { imgPath: "clock22.png", correct: true },
        { imgPath: "clock23.png", correct: false }
      ]
    }
  ],
  dogCanvasGame: [
    "right",
    "right",
    "up",
    "right",
    "up",
    "right",
    "down",
    "down",
    "down",
    "down",
    "right",
    "right",
    "right",
    "up",
    "right",
    "down",
    "down",
    "down",
    "down",
    "down",
    "left",
    "left",
    "up",
    "left",
    "left",
    "down",
    "left",
    "left",
    "up",
    "up",
    "up",
    "up",
    "left",
    "left",
    "up",
    "up"
  ]
};
