import React, { useEffect, useState } from "react";
import { useDrop } from "react-dnd";
import { DraggableWordType } from "../common/types/dndTypes";

type ImagePropType = {
  word: string;
  image: string;
};

const DropTargetImage: React.FC<{
  imgProp: ImagePropType;
  getNextQuestion: any;
  handleAnswer: Function;
  idx: number;
}> = ({ imgProp, getNextQuestion, handleAnswer, idx }) => {
  const handleClick = () => {
    getNextQuestion();
    handleAnswer(imgProp.word);
  };

  return (
    <div
      style={{
        opacity: 1,
        border: "2px dotted rgba(0,0,0,.2)",
        position: "relative"
        // backgroundImage: `url(${process.env.PUBLIC_URL}/images/matchWordWithPictureGame/pingviin1.png`,
        // backgroundSize: "cover"
      }}
      onClick={handleClick}
      className="drag-word-to-picture-game-drop-target-image"
    >
      <img
        style={{ opacity: 1, zIndex: 5 }}
        className="drag-word-to-picture-game-option"
        src={
          `${process.env.PUBLIC_URL}/images/matchWordWithPictureGame/penguin${idx}.png`
        }
        alt={imgProp.word}
      />
      <div
        style={{ 
          position: "absolute",
          bottom: 0,
          left: 0,
          padding: "5% 5% 3% 5%"
        }}
      >
        <img
          style={{ opacity: 1, width: "100%", height: "auto" }}
          className="drag-word-to-picture-game-option"
          src={process.env.PUBLIC_URL + imgProp.image}
          alt={imgProp.word}
        />
      </div>
    </div>
  );
};

export default DropTargetImage;
