import React, {
  CSSProperties,
  forwardRef, useEffect,
  useImperativeHandle,
  useMemo,
  useState
} from "react";



interface AudioTextProps {
  style?: CSSProperties;
  audioFile: string,
  border?: boolean,
  children?: React.ReactNode,
  onClick?: Function,
  unpausable?: boolean,
  disabled?: boolean,
  newline?: boolean,
  showImage?: boolean,
  noClick?: boolean,
  divStyle?: CSSProperties,
}

export interface AudioTextRef {
  clickAudio(): void
}

const AudioText = forwardRef<AudioTextRef, AudioTextProps>(({
        style,
        audioFile,
        children,
        onClick,
        unpausable,
        disabled,
        border = true,
        newline= false,
        showImage= true,
        noClick = false,
        divStyle,
      }: AudioTextProps, ref) => {
  const [isPlaying, setPlaying] = useState<boolean>(false);

  const audio = useMemo(
    () => new Audio(process.env.PUBLIC_URL + "/audio/" + audioFile),
    [audioFile]
  );

  useImperativeHandle(
    ref,
    () => ({
      clickAudio() {
        if (unpausable) {
          setPlaying(true);
        } else {
          setPlaying(c => !c);
        }

        if ((!unpausable || !isPlaying) && onClick) {
          onClick();
        }
      }
    }),
  )

  const handleClick = () => {
    if (disabled || noClick) return;

    if (unpausable) {
      setPlaying(true);
    } else {
      setPlaying(c => !c);
    }

    if ((!unpausable || !isPlaying) && onClick) {
      onClick();
    }
  };

  useEffect(() => {
    if (isPlaying) {
      audio.currentTime = 0;
      audio.play();
      audio.onended = () => {
        setPlaying(false);
      };
    } else {
      audio.pause();
      audio.currentTime = 0;
    }
  }, [isPlaying]);

  const borderStyle: React.CSSProperties = {
    border: "1px #d8d8d8 solid",
    borderRadius: "10px",
    paddingLeft: "5px"
  }

  return (
    <div onClick={handleClick} style={{
      display: "inline-flex",
      alignItems: "center",
      flexDirection: newline ? "column" : "row",
      ...(border ? borderStyle : {}),
      ...divStyle
    }}>
      <div>
        {children}
      </div>
      {showImage &&
      <img
          alt="Play Audio"
          style={{
            height: "3rem",
            border: isPlaying ? "1px solid black" : "none",
            borderRadius: "8px",
            padding: "0.2rem",
            ...style,
          }}
          src={process.env.PUBLIC_URL + "/icons/audio.svg"}
          className={disabled ? "audio-btn-txt disabled" : "audio-btn-txt"}
      />
      }
    </div>
  );
});

export default AudioText;
