import React from "react";
import { useDrag } from "react-dnd";
import { DraggableWordType } from "../common/types/dndTypes";
import SequenceNumber from "./sequenceNumber";

const DraggableSequenceNumber: React.FC<{
  value: number;
  droppedIdx?: number;
}> = ({ value, droppedIdx }) => {
  const [dragProps, drag] = useDrag({
    item: {
      type: DraggableWordType.NUMBER,
      value: value,
      dropIdx: droppedIdx ? droppedIdx : 0
    },
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
      position: monitor.getDifferenceFromInitialOffset()
    })
  });

  return (
    <div
      style={{
        opacity: dragProps.isDragging ? 0.2 : 1,
        // filter: dragProps.isDragging ? "grayscale(100%)" : "",
        transition: "all .2s",
      }}
    >
      <SequenceNumber value={value} drag={drag}/>
    </div>
  );
};

export default DraggableSequenceNumber;
