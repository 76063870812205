import React from "react";

const SequenceNumber: React.FC<{
  value: number;
  drag?: any;
  style?: any;
  disabled?: boolean;
}> = ({value, drag, style, disabled}) => {

  let imgName = "keha.png";
  if (disabled) {
    imgName = "keha_locked.png";
  } else if (isNaN(value)) {
    imgName = "keha_missing.png";
  }

  return (
    <div
      className="number-sequencing-game-element"
      style={{

        ...style,
        position: "relative"
      }}
      ref={drag}
    >

      <div
        style={{

          height: "15rem",
          width: "auto"
        }}
      >
        <img
          src={`${process.env.PUBLIC_URL}/images/numberSequenceGame/${imgName}`}
          style={{
            height: "100%",
            width: "auto"
          }}
        />
        {!isNaN(value) && (
          <div
            style={{
              position: "absolute",
              bottom: 0,
              top: 0,
              fontSize: "5rem"
            }}
          >
            <svg
              width="100%"
              height="100%"
              viewBox="0 0 293 459"
              preserveAspectRatio="xMinYMid meet"
            >
              <text
                x="145"
                y="254"
                fontSize="175"
                textAnchor="middle"
                pointerEvents={"none"}
              >
                {value}
              </text>
            </svg>
          </div>
        )}
      </div>

    </div>

  );
};

export default SequenceNumber;
