import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {AppState} from "../../store/store";
import {Button} from "reactstrap";
import AudioBtn from "../common/audioBtn";
import AudioText from "../common/audioText";

const GameEnd = () => {
    // const results = useSelector<AppState, any>(state => state.game.results);
    const [resultsState, setResultsState] = useState<any[]>([]);
    const game = useSelector<AppState, any>(state => state.game);
    useEffect(() => {
        let tempArr: any[] = [];
        Object.keys(game.results).forEach((key) => {
            // @ts-ignore
            tempArr.push(game.results[key]);
        });
        setResultsState(tempArr);

        submitResults();
    }, []);

    const renderResults = () => {
        return (
            <div className="game-end-wrapper">
                <div className="game-end-heading">Test on sooritatud!</div>
                <h2>MÄNGIJA - {game.player}</h2>
                <h3>ÕPETAJA - {game.teacher}</h3>
                <div className="game-end-dev-results">
                    {resultsState.length ? resultsState.map((game, idx) => {
                        return <div className="game-end-dev-game-results">{idx + 1}.
                            Mäng {game.map((result: any, resultIdx: number) => {
                                if (!Array.isArray(result)) {
                                    return <div className="game-end-dev-game-result">{resultIdx + 1}
                                        <div>ÕIGE VASTUS - {result.expected || ""}</div>
                                        <div>LAPSE VASTUS - {result.answer || ""}</div>
                                        <div>KAS ÕIGE? - {result.correct ? "JAH" : "EI"}</div>
                                    </div>
                                } else {
                                    return <div className="game-end-dev-game-result">
                                        {resultIdx + 1 + ". ÜLESANNE"}
                                        {result.map((subResult, idx) => {
                                            return <div className="game-end-dev-game-result">
                                                <div>{idx + 1}. KÜSIMUS <div>LAPSE VASTUS - {subResult.answer}</div>
                                                </div>
                                                <div>ÕIGE VASTUS - {subResult.expected}</div>
                                                <div>KAS ÕIGE - {subResult.correct ? "JAH" : "EI"}</div>
                                            </div>
                                        })}
                                    </div>
                                }

                            })}
                        </div>
                    }) : ""}
                </div>
            </div>
        );
    };

    const submitResults = () => {
        console.log(game);
        fetch(`${window.env.API_URL}/api/results`,
            {
                method: "POST",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(game)
            });
    };

    const renderMessage = () => {

        return (
            <div className="game-over-message-wrapper">
                <div className="game-init__body">
                    <img src={`${process.env.PUBLIC_URL}/images/logo/paasuke1.png`}
                        style={{height: "40vh"}}
                    />
                    <span className="game-over-message">
                      <AudioText style={{marginLeft: "1rem"}} audioFile="/final/game-over-message.m4a">
                        Olid väga tubli
                      </AudioText>
                    </span>
                </div>
            </div>
        )
    };


    return (
        <React.Fragment>{renderMessage()}</React.Fragment>
    )
};

export default GameEnd;