export const SET_COMPARE_QUANTITIES_GAME_STARTED = () => ({
  type: "SET_COMPARE_QUANTITIES_GAME_STARTED"
});

export const SET_COMPARE_QUANTITIES_GAME_COMPLETED = () => ({
  type: "SET_COMPARE_QUANTITIES_GAME_COMPLETED"
});

export const SET_COMPARE_QUANTITIES_GAME_CURRENT_QUESTION = (
  payload: number
) => ({
  type: "SET_COMPARE_QUANTITIES_GAME_CURRENT_QUESTION",
  payload: payload
});

export const SET_COMPARE_QUANTITIES_GAME_SELECTED_ANSWER = (
  payload: number
) => ({
  type: "SET_COMPARE_QUANTITIES_GAME_SELECTED_ANSWER",
  payload: payload
});
