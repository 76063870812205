import React from "react";
import { Draggable } from "react-beautiful-dnd";
import AudioText from "../common/audioText";

const DraggableMonth: React.FC<{ month: string; idx: number, audioRef: any }> = ({
  month,
  idx,
  audioRef
}) => {

  return (
    <Draggable key={month} draggableId={month} index={idx}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          onClick={() => audioRef?.current?.clickAudio()}
        >
          <div
            // style={{ opacity: dragProps.isDragging ? 0 : 1, transition: "all .1s" }}
            className="months-game-draggable-month"
          >
            <AudioText ref={audioRef} noClick={true} audioFile={`/task4/task4-month-${month}.m4a`} border={false}>

            {month}
            </AudioText>
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default DraggableMonth;
