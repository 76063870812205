import React, { useEffect, useState } from "react";
import "./css/style.css";
import Layout from "./components/layout/layout";
import Game from "./components/game/game";
import GameInitLanding from "./components/game/gameInitLanding";
import { useSelector } from "react-redux";
import { AppState } from "./store/store";
import { GameReducerStateType } from "./components/game/reducers/gameReducer";
import GameEnd from "./components/game/gameEnd";

// Uncommenting the commented lines allows to access teachers edit url from path /editTeachers
// import EditTeachers from "./data/editTeachers";

// const teacherEditPath = "/editTeachers";

function App() {
  useEffect(() => {}, []);
  const game = useSelector<AppState, GameReducerStateType>(state => state.game);
  // const currentUri = window.location.pathname;
  return (
    <div>
      {
        // currentUri != teacherEditPath ?
        (<Layout>
          {!game.gameInitiated && !game.gameCompleted ? <GameInitLanding/> : ""}
          {game.gameInitiated && !game.gameCompleted ? <Game/> : ""}
          {game.gameCompleted && !game.gameInitiated ? <GameEnd/> : ""}
        </Layout>)
        // : <EditTeachers/>
      }
    </div>
  );
}

export default App;
