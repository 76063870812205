import React, { useEffect } from "react";
import { useDrag } from "react-dnd";
import { DraggableWordType } from "../common/types/dndTypes";
import { WeekdaysGameWeekdayType } from "./weekdaysGame";
//@ts-ignore
import Preview from "react-dnd-preview";
import AudioBtn from "../common/audioBtn";
import AudioText from "../common/audioText";

const DraggableWeekday: React.FC<{
  weekday: WeekdaysGameWeekdayType;
  style?: any;
  dropped?: boolean
}> = ({ weekday, style, dropped }) => {
  const [dragProps, drag] = useDrag({
    item: { type: DraggableWordType.WEEKDAY, weekday: weekday },
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
      position: monitor.getDifferenceFromInitialOffset()
    })
  });

  // const GeneratePreview = ({ itemType, item, style }: any) => {
  //   return (
  //     <div className="weekday-game-draggable-weekday" style={style}>
  //       {item.weekday.label}
  //     </div>
  //   );
  // };

  return (
    <React.Fragment>
      <div
        style={{
          opacity: dragProps.isDragging ? 0 : 1,
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/kalender/bg2.png)`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% auto",
          transition: "all .2s", ...style
        }}
        className={!dropped ? "weekday-game-draggable-weekday" : "weekday-game-draggable-weekday-dropped"}
        ref={drag}
      >
        {/*{dropped ? null : <AudioBtn audioFile={`/task5/task5-weekday-${weekday.label}.m4a`} />}*/}
        <AudioText audioFile={`/task5/task5-weekday-${weekday.label}.m4a`} border={false} newline={true} showImage={!dropped}
          style={{height: "2.4rem", marginTop: "0.4rem"}}
        >
          <span className="weekday-game-draggable-weekday-text">{weekday.label}</span>

        </AudioText>
        {/*<Preview generator={(props: any) => GeneratePreview(props)} />*/}
      </div>
    </React.Fragment>
  );
};

export default DraggableWeekday;
