import React from "react";
import NumberSequence from "../numberSequence";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../store/store";
import GameDescription from "../../common/gameDescription";
import AudioBtn from "../../common/audioBtn";
import GameContent from "../../common/gameContent";
import AudioText from "../../common/audioText";

const NumberSequencingOrderRandomGame: React.FC<{
    questions: Array<{ availableNumbers: number[]; originalSequence: number[] }>;
    handleNextAssignment: any;
}> = ({questions, handleNextAssignment}) => {
    const dispatch = useDispatch();
    const gameResults = useSelector<AppState, any>(state => state.game.results);

    return (
        <React.Fragment>
            <GameDescription>
              <AudioText audioFile="/task6/task6-assignment2-tutorial.m4a">
                Järjesta numbrid väiksemast suuremani või suuremast väiksemani
              </AudioText>
            </GameDescription>
            <GameContent>
                <NumberSequence questions={questions} handleNextAssignment={handleNextAssignment}/>
            </GameContent>
        </React.Fragment>
    );
}

export default NumberSequencingOrderRandomGame;