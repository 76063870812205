import React from "react";
import NumberSequence from "../numberSequence";
import GameDescription from "../../common/gameDescription";
import AudioBtn from "../../common/audioBtn";
import GameContent from "../../common/gameContent";
import AudioText from "../../common/audioText";

const NumberSequencingEmptyFieldGame: React.FC<{
    questions: Array<{ availableNumbers: number[]; originalSequence: number[] }>;
    handleNextAssignment: any;
}> = ({questions, handleNextAssignment}) => {

    return (
        <React.Fragment>
            <GameDescription>
              <AudioText audioFile="/task6/task6-assignment1-tutorial.m4a">
                  Moodusta arvujada, kus numbrid on väiksemast suuremani või suuremast
                  väiksemani
              </AudioText>
            </GameDescription>
            <GameContent>
                <NumberSequence questions={questions} handleNextAssignment={handleNextAssignment}/>
            </GameContent>
        </React.Fragment>
    );
};

export default NumberSequencingEmptyFieldGame;
