import React, {createRef, useEffect, useState} from "react";
import MatchOppositeWordDropZone from "./matchOppositeWordDropZone";
import MatchOppositeWordDraggableWord from "./matchOppositeWordDraggableWord";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../store/store";
import {
  SET_DRAG_WORD_TO_PICTURE_GAME_CURRENT_ASSIGNMENT,
  SET_MATCH_OPPOSITE_WORDS_GAME_CURRENT_QUESTION,
  SET_MATCH_OPPOSITE_WORDS_GAME_QUESTIONS
} from "../actions/dragWordToQuestionGameActions";
import {MatchOppositeWordsGameQuestionType} from "./matchOppositeWordsGameTypes";
import {shuffleArray} from "../../common/helpers/arrayHelpers";
import {NextAssignmentBtn, NextQuestionBtn} from "../../common/gameButtons";
import GameQuestionCounter from "../../common/gameQuestionCounter";
import {POST_GAME_RESULTS} from "../../game/actions/gameActions";
import GameDescription from "../../common/gameDescription";
import GameContent from "../../common/gameContent";
import {DragDropContext, Droppable, DropResult, ResponderProvided} from "react-beautiful-dnd";
import AudioText from "../../common/audioText";


const MatchOppositeWordGame: React.FC<{
  questions: Array<Array<{ word: string; image: string; match: string }>>;
}> = ({questions}) => {
  const dispatch = useDispatch();
  const gameResults = useSelector<AppState, Array<any>>(
    state => state.game.results
  );
  const currentQuestion = useSelector<AppState, number>(
    state => state.dragWordToPictureGame.matchOppositeWordsGame.currentQuestion
  );
  const currentGameAssignment = useSelector<AppState, number>(
    state => state.dragWordToPictureGame.currentAssignment
  );
  const [optionSlots, setOptionSlots] = useState<Array<string>>([]);
  const [results, setResults] = useState<Array<any>>([]);

  const [droppedSlots, setDroppedSlots] = useState<Array<string>>(["", "", "", ""]);

  const allQuestions = useSelector<AppState, Array<any>>(
    state => state.dragWordToPictureGame.matchOppositeWordsGame.questions
  );

  let lastDragStart = Date.now();
  const [wordRefs, setWordRefs] = useState<Map<string, any>[]>([new Map(), new Map()]);

  useEffect(() => {
    let questionsArr: any = [];

    questions.forEach(question => {
      let questionObj: any = {question: question};
      let options: any = [];

      question.forEach(item => {
        options.push(item.match);
        questionObj.options = options;
      });
      questionsArr.push(questionObj);
    });

    setWordRefs((wordRefs) => {
      let m = [new Map(), new Map()];
      questions[0].forEach(x => m[0].set(x.match, wordRefs[0]?.get(x.match) || createRef<any>()));
      questions[1].forEach(x => m[1].set(x.match, wordRefs[1]?.get(x.match) || createRef<any>()));
      return m;
    })

    dispatch(SET_MATCH_OPPOSITE_WORDS_GAME_QUESTIONS(questionsArr));
  }, [dispatch, questions]);

  useEffect(() => {
    if (allQuestions.length) {
      setOptionSlots([...shuffleArray(allQuestions[currentQuestion].options)]);
    }
  }, [dispatch, allQuestions, currentQuestion]);



  const updateResults = () => {
    let resultArr: Array<any> = [...results];
    droppedSlots.map((x, idx) => {
      resultArr.push({
        expected: questions[currentQuestion][idx].match,
        answer: x,
        correct: x === questions[currentQuestion][idx].match
      });
    })

    setResults(resultArr);
    return resultArr;
  }

  const handleNextQuestion = () => {
    updateResults();
    setDroppedSlots(["", "", "", ""])
    dispatch(
      SET_MATCH_OPPOSITE_WORDS_GAME_CURRENT_QUESTION(currentQuestion + 1)
    );
  };

  const handleNextAssignment = () => {
    let curResults = updateResults();
    console.log(results);
    dispatch(
      POST_GAME_RESULTS(
        Object.assign({...gameResults}, {matchOppositeWordGame: curResults})
      )
    );
    dispatch(
      SET_DRAG_WORD_TO_PICTURE_GAME_CURRENT_ASSIGNMENT(
        currentGameAssignment + 1
      )
    );
  };

  function onDragStart(initial: DropResult, provided: ResponderProvided) {
    lastDragStart = Date.now();
  }

  function onDragEnd(result: DropResult, provided: ResponderProvided) {
    const lastDragEnd = Date.now();
    if (lastDragEnd - lastDragStart < 500 && (result.source?.droppableId == result.destination?.droppableId || result.destination?.droppableId == undefined)) {
      wordRefs[currentQuestion].get(result.draggableId)?.current?.clickAudio();
    }

    if (result.destination == null) {
      return;
    }

    let destinationLabel = result.destination.droppableId;
    let sourceLabel = result.source.droppableId;

    if (destinationLabel === "source") {
      let slotArr = [...optionSlots];
      let movedElement: string;
      if (sourceLabel === "source") {
        [movedElement] = slotArr.splice(result.source.index, 1);
      } else {
        let tmpDroppedSlots = [...droppedSlots]
        movedElement = tmpDroppedSlots[+sourceLabel];
        tmpDroppedSlots[+sourceLabel] = "";
        setDroppedSlots(tmpDroppedSlots);
      }
      slotArr.splice(result.destination.index, 0, movedElement);
      setOptionSlots(slotArr);
      return;
    }

    let tmpDroppedSlots = [...droppedSlots];
    let idx = parseInt(destinationLabel);

    let slotArr = [...optionSlots];
    if (sourceLabel === "source") {
      slotArr = slotArr.filter((x) => x != result.draggableId);
    }
    if (tmpDroppedSlots[+destinationLabel] != "") {
      slotArr.push(tmpDroppedSlots[+destinationLabel]);
    }
    setOptionSlots(slotArr);

    if (!isNaN(+sourceLabel)) {
      tmpDroppedSlots[+sourceLabel] = "";
    }

    tmpDroppedSlots[idx] = result.draggableId;

    setDroppedSlots(tmpDroppedSlots);
  }

  return (
    <React.Fragment>
      <GameDescription>
        <AudioText audioFile={"/task2/task2-assignment2-tutorial.m4a"}>
          Otsi ülemise rea sõnadele alumisest reast vastandsõna
        </AudioText>
      </GameDescription>
      <GameContent>
        <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>

          <div className="match-opposite-words-game-wrapper">
            <GameQuestionCounter
              totalAmountOfQuestions={questions.length}
              currentQuestion={currentQuestion + 1}
            />
            <div className="match-opposite-words-game-options">
              {allQuestions.length
                ? allQuestions[currentQuestion].question.map(
                  (question: MatchOppositeWordsGameQuestionType, idx: number) => {
                    return (
                      <MatchOppositeWordDropZone
                        key={question.word + question.match}
                        question={question}
                        item = {droppedSlots[idx]}
                        idx = {idx}
                        wordRefs={wordRefs[currentQuestion]}
                      />
                    );
                  }
                )
                : ""}
            </div>
            <div
              className="match-opposite-words-game-options"
              style={{marginTop: "2rem"}}
            >
              {droppedSlots.some(x => x === "") ? (

                    <Droppable droppableId="source"
                               direction="horizontal"
                               isDropDisabled={false}>
                      {(provided, snapshot) => (
                        <div
                          style={{display: "flex"}}
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {optionSlots.map((option: string, idx: number) => {
                            return <MatchOppositeWordDraggableWord key={option} word={{word: option}} idx={idx} className={"match-opposite-words-draggable-box"} audioRef={wordRefs[currentQuestion].get(option)}/>
                          })
                          }

                        {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
              ) : allQuestions.length > currentQuestion + 1 ? (
                <NextQuestionBtn handleClick={handleNextQuestion}/>
              ) : (
                <div style={{display: "flex", flexDirection: "column"}}>
                  <NextAssignmentBtn handleClick={handleNextAssignment}/>
                </div>
              )}

            {/*{!droppedSlots.some(x => x === "") && <div style={{display: "flex"}}><NextQuestionBtn handleClick={handleNextQuestion}/></div>}*/}
            </div>
          </div>
        </DragDropContext>
      </GameContent>
    </React.Fragment>
  );
};

export default MatchOppositeWordGame;
